import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { UserService } from "app/user/user.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private jwtHelper: JwtHelperService
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.userService.isLoggedIn() == true) {
      let token = localStorage.getItem("id_token") || "";
      let user = this.jwtHelper.decodeToken(token);
      if (user) {
        return new Promise((resolve, reject) => {
          return this.validateUser(next, resolve, user);
        });
      } else {
        return false;
      }
    }

    this.userService.logout();
    return false;
  }

  private validateUser(next: any, resolve: any, user: any) {
    if (!next.data) {
      resolve(true);
    } else {
      this.userService.getSingleUser(user?.userId).subscribe((res: any) => {
        if (res) {
          let userPermissions = [];
          if (res?.permissions) {
            userPermissions = res.permissions.map(
              (permission: any) => permission.code
            );
            localStorage.setItem(
              "specificatorPermissions",
              JSON.stringify(userPermissions)
            );
          }
          if (res?.role?.code) {
            const role = res.role.code;
            localStorage.setItem("role", role);
            const pathAccessibleRoles = next.data?.role;
            const pathAccessiblePermissions = next.data?.permissions;
            // jezeli sa wymagania dostepowe co do roli
            if (pathAccessibleRoles) {
              // jezeli nie ma wśród uprawnionych ról określonej roli
              if (pathAccessibleRoles.indexOf(role) === -1) {
                if (pathAccessiblePermissions) {
                  let checkIfHasPermission = userPermissions.some(
                    (permission: any) =>
                      pathAccessiblePermissions.includes(permission)
                  );
                  // jezeli ma pasujące uprawnienie - nie wyloguj
                  if (checkIfHasPermission) {
                    resolve(true);
                  }
                  // jezeli nie ma pasującego uprawnienia - wyloguj
                  else {
                    resolve(false);
                    this.userService.logout();
                  }
                }
                // jezeli dodatkowo uprawnien tez uzytkownik nie ma
                else {
                  resolve(false);
                  this.userService.logout();
                }
              }
              // jezeli uzytkownik ma rolę uprawnioną do dostępu do scieżki
              else {
                resolve(true);
              }
            }
            // jezeli nie ma wymagan dostepowych co do roli
            else if (!pathAccessibleRoles) {
              // jezeli sa wymagania dostępowe co do uprawnienia
              if (pathAccessiblePermissions) {
                let checkIfHasPermission = userPermissions.some(
                  (permission: any) =>
                    pathAccessiblePermissions.includes(permission)
                );
                // jezeli ma pasujące uprawnienie - nie wyloguj
                if (checkIfHasPermission) {
                  resolve(true);
                }
                // jezeli nie ma pasującego uprawnienia - wyloguj
                else {
                  resolve(false);
                  this.userService.logout();
                }
              }
              // jezeli nie ma wymagań co do uprawnienia
              else {
                resolve(true);
              }
            }
          }
          // w przypadku braku roli
          else {
            resolve(false);
            this.userService.logout();
          }
        }
        // Brak usera
        else {
          resolve(false);
          this.userService.logout();
        }
      });
      resolve(true);
    }
  }
}
