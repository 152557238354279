import { Injectable } from "@angular/core";
import { Notification } from "app/notifications/notification";
import {
  ProductionElement,
  ProductionStage,
} from "app/production/production.model";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { Socket, io } from "socket.io-client";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  public message$: BehaviorSubject<string> = new BehaviorSubject("");
  private socket: Socket;
  constructor() {
    this.socket = io(environment.url);
    this.getNewMessage();
  }

  public sendMessage(message: any) {
    this.socket.emit("message", message);
  }

  public getNewMessage = () => {
    this.socket.on("message", (message) => {
      this.message$.next(message);
    });

    return this.message$.asObservable();
  };

  public onProductionElementUpdate(
    callback: (data: ProductionElement) => void
  ) {
    this.socket.on("productionElements", callback);
  }

  public onStageUpdate(callback: (data: ProductionStage) => void) {
    this.socket.on("stageUpdate", callback);
  }

  public onNotificationCreate(callback: (data: Notification) => void) {
    this.socket.on("notificationCreate", callback);
  }
}
