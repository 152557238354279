<ng-container *ngIf="userDetails?.notifications">
  <div class="toolbar">

    <mat-button-toggle-group value="all">
      <mat-button-toggle (click)="loadNotifications('')" value="all">
        Wszystkie
      </mat-button-toggle>
      <mat-button-toggle *ngIf="userDetails?.role?.code === 'superadmin' || canViewOrderNotifications()"
        (click)="loadNotifications('orders')" value="orders">
        Zamówienia
      </mat-button-toggle>
      <mat-button-toggle
        *ngIf="userDetails?.role?.code === 'superadmin' ||userDetails?.notifications?.includes('submission_harden')"
        (click)="loadNotifications('submissions')" value="submissions">
        Zgłoszenia
      </mat-button-toggle>
      <mat-button-toggle
        *ngIf="userDetails?.role?.code === 'superadmin' || userDetails?.notifications?.includes('financial_quarter_report_edit')"
        (click)="loadNotifications('financials')" value="financials">
        Księgowość
      </mat-button-toggle>
    </mat-button-toggle-group>

    <!-- <button mat-button (click)="loadNotifications('orders')">ZAMÓWIENIA</button>
    <button mat-button (click)="loadNotifications('submissions')">ZGŁOSZENIA</button>
    <button mat-button (click)="loadNotifications('financials')">KSIĘGOWOŚĆ</button> -->
  </div>

  <div #notificationList class="col-md-12 notification-list" (scroll)="onScroll()">
    <ng-container *ngFor="let group of groupedNotifications">
      <div class="group">
        <div class="group-date">{{ group.date }}</div>
        <div class="notification-item" *ngFor="let notification of group.notifications">
          <mat-list-item (click)="markAsRead($event, notification)">

            <div matListItemTitle> <strong>{{notification.createdBy.firstName}}
                {{notification.createdBy.lastName}}</strong><br />{{ notification.description }}</div>
            <div matListItemLine><span>{{ notification.createdAt | date : "dd.MM.yyyy, HH:mm" }}</span>
              <ng-container
                *ngIf="['order_harden', 'order_advance_payment_added', 'order_archive'].includes(notification.type)">
                <button (click)="navigateToOrder(notification.order)" class="notification-details" mat-icon-button>
                  <mat-icon color="primary">navigate_next</mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="['order_cost_added'].includes(notification.type)">
                <button (click)="navigateToOrderCosts(notification.order)" class="notification-details" mat-icon-button>
                  <mat-icon color="primary">navigate_next</mat-icon>
                </button>
              </ng-container>
            </div>
            <mat-icon class="circle" matListItemIcon
              [ngClass]="checkIfNotificationIsRead(notification) ? 'circle-read' : 'circle-unread'">circle</mat-icon>
          </mat-list-item>

          <!-- <div>{{ notification.createdAt | date: 'medium' }}</div>
          <div>{{ notification.description }}</div> -->
        </div>
      </div>
    </ng-container>

    <div *ngIf="loading && !endOfList" style="margin-top:20px;">Wczytywanie...</div>
    <div *ngIf="!loading && groupedNotifications.length === 0" style="margin-top:20px;">Brak powiadomień</div>
  </div>
</ng-container>