import {
  Component,
  AfterViewInit,
  EventEmitter,
  Output,
  OnInit,
} from "@angular/core";
import { NgbDropdownModule, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { UserService } from "app/user/user.service";
import { LanguageService } from "app/language/language.service";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { CommonModule } from "@angular/common";
import { NotificationsLastListComponent } from "app/notifications/notifications-last-list/notifications-last-list.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatBadgeModule } from "@angular/material/badge";
import { NotificationService } from "app/notifications/notification.service";
import { SocketService } from "app/services/sockets/socket.service";
import { Notification } from "app/notifications/notification";
import { JwtHelperService } from "@auth0/angular-jwt";
@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    MatOptionModule,
    MatSelectModule,
    NotificationsLastListComponent,
    NgbDropdownModule,
    MatSlideToggleModule,
    MatBadgeModule,
  ],
})
export class NavigationComponent implements AfterViewInit, OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  isMenuOpened: boolean = false;
  public config: PerfectScrollbarConfigInterface = {};
  constructor(
    private modalService: NgbModal,
    private _userService: UserService,
    private _languageService: LanguageService,
    public translate: TranslateService,
    private _notificationService: NotificationService,
    private _socketService: SocketService,
    private jwtHelper: JwtHelperService
  ) {}

  role: string | null;
  user: any;
  userDetails: any;
  firstName: string;

  // lista jezykow
  languages: any = [];

  viewOrderEquipmentCosts: boolean;

  // jezyk dla tlumaczen
  language: any;

  logout() {
    this._userService.logout();
  }

  /**
   * Funkcja wywoływana w momencie otwarcia menu powiadomień
   */
  onNotificationMenuOpened() {
    this.isMenuOpened = true;
  }

  /**
   * Funkcja wywoływana w momencie zamknięcia menu powiadomień
   */
  onNotificationMenuClosed() {
    this.isMenuOpened = false;
  }

  /**
   * Getter zwracajacy ilość nieodczytanych powiadomień
   */
  get unreadNotificationsCounter() {
    return this._notificationService.unreadNotificationsCounter;
  }

  ngAfterViewInit() {}
  ngOnInit() {
    this.getCurrentUser();
    this.role = localStorage.getItem("role") || "";
    let firstName = localStorage.getItem("firstName");
    if (firstName) {
      this.firstName = firstName;
    }
    this.fetchLanguages();

    this.viewOrderEquipmentCosts = localStorage.getItem(
      environment.specificatorViewOrderEquipmentCosts
    )
      ? true
      : false;

    this.language = localStorage.getItem(environment.specificatorLanguage)
      ? localStorage.getItem(environment.specificatorLanguage)
      : "PL";
    this.translate.setDefaultLang(this.language);
    this.translate.use(this.language); //-

    /**
     * W przypadku, gdy autor powiadomienia nie jest zalogowanym użytkownikiem
     */
    this._socketService.onNotificationCreate((notification: Notification) => {
      if (
        notification.createdBy !== this.user.userId &&
        this._notificationService.canViewNotification(
          this.userDetails,
          notification
        )
      ) {
        this._notificationService.unreadNotificationsCounter++;
      }
    });
  }

  /**
   * Funkcja odpowiedzialna za pobranie danych dot. zalogowanego użytkownika
   * @since 13.12.2022
   */
  private getCurrentUser() {
    const token = localStorage.getItem("id_token");
    if (token) {
      this.user = this.jwtHelper.decodeToken(token);
      this._userService.getSingleUser(this.user.userId).subscribe((res) => {
        this.userDetails = res;
      });
    }
  }

  /**
   *
   */
  fetchLanguages() {
    this._languageService.getLanguages().subscribe((res: any) => {
      this.languages = res.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    });
  }

  /**
   * Funkcja odpowiedzialna za aktualizację możliwości widoczności kosztów ekwipunku (tooltipów) w module podglądu zamówienia
    @since 22.06.2022
  */
  updateViewOrderEquipmentCostsValue() {
    if (this.viewOrderEquipmentCosts === false) {
      localStorage.removeItem(environment.specificatorViewOrderEquipmentCosts);
    } else {
      localStorage.setItem(
        environment.specificatorViewOrderEquipmentCosts,
        JSON.stringify(this.viewOrderEquipmentCosts)
      );
    }
    this._userService
      .updateUser({ viewOrderEquipmentCosts: this.viewOrderEquipmentCosts })
      .subscribe((res) => {});
  }

  /**
   *
   * @param event
   */
  setLanguage(event: any) {
    this.language = event.value;
    localStorage.setItem(environment.specificatorLanguage, this.language);
    this._userService
      .updateUser({ language: this.language })
      .subscribe((res: any) => {});
    this.translate.setDefaultLang(this.language);
    this.translate.use(this.language); //--> I didn't had this line before
  }
}
