import { Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { FullComponent } from "./layouts/full/full.component";
import { OrderResolver } from "app/orders/order/order.resolvers";
import { SubmissionResolver } from "app/submissions/submission/submission.resolvers";
import { NotificationsListComponent } from "./notifications/notifications-list/notifications-list.component";

export const Approutes: Routes = [
  {
    path: "",
    component: FullComponent,
    canActivate: [AuthGuard],

    children: [
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
        data: {
          role: [
            "superadmin",
            "admin",
            "seller",
            "seller_external",
            "translator",
            "user",
            "warehouse_manager",
            "warehouser",
            "accountant",
          ],
        },
      },
      {
        path: "equipment",
        loadChildren: () =>
          import("./equipment/equipment.module").then((m) => m.EquipmentModule),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin", "warehouse_manager"],
          permissions: ["VIEW_AND_UPDATE_EQUIPMENT"],
        },
      },
      {
        path: "equipment/details",
        loadChildren: () =>
          import("./equipment/equipment-details/equipment-details.module").then(
            (m) => m.EquipmentDetailsModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin", "warehouse_manager"],
          permissions: ["VIEW_AND_UPDATE_EQUIPMENT"],
        },
      },
      {
        path: "equipment/create",
        loadChildren: () =>
          import("./equipment/equipment-create/equipment-create.module").then(
            (m) => m.EquipmentCreateModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin", "warehouse_manager"],
          permissions: ["VIEW_AND_UPDATE_EQUIPMENT"],
        },
      },
      {
        path: "conversions",
        loadChildren: () =>
          import("./conversions/conversions-list/conversions-list.module").then(
            (m) => m.ConversionsListModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin", "warehouse_manager"],
          permissions: ["VIEW_AND_UPDATE_CONVERSIONS"],
        },
      },
      {
        path: "production",
        loadChildren: () =>
          import("./production/production.module").then(
            (m) => m.ProductionModule
          ),
        canActivate: [AuthGuard],
        data: {
          permissions: ["VIEW_PRODUCTION"],
        },
      },
      {
        path: "financial-reports",
        loadChildren: () =>
          import("./financial-reports/financial-reports.module").then(
            (m) => m.FinancialReportsModule
          ),
        canActivate: [AuthGuard],
        data: {
          permissions: [
            "MANAGE_FINANCIAL_REPORTS",
            "MANAGE_FINANCIAL_REPORT_WAREHOUSE_MODULE",
            "MANAGE_FINANCIAL_REPORT_ORDERS_MODULE",
          ],
        },
      },
      {
        path: "conversion/create",
        loadChildren: () =>
          import(
            "./conversions/conversion-create/conversion-create.module"
          ).then((m) => m.ConversionCreateModule),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin", "warehouse_manager"],
          permissions: ["VIEW_AND_UPDATE_CONVERSIONS"],
        },
      },
      {
        path: "producent/create",
        loadChildren: () =>
          import("./producent/producent-create/producent-create.module").then(
            (m) => m.ProducentCreateModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin"],
        },
      },
      {
        path: "producent/details",
        loadChildren: () =>
          import("./producent/producent-details/producent-details.module").then(
            (m) => m.ProducentDetailsModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin"],
        },
      },
      {
        path: "orders",
        loadChildren: () =>
          import("./orders/orders-list/orders-list.module").then(
            (m) => m.OrdersListModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: [
            "superadmin",
            "admin",
            "seller",
            "seller_external",
            "warehouse_manager",
            "warehouser",
          ],
        },
      },
      {
        path: "order/manage-order",
        redirectTo: "order/manage-order/",
        pathMatch: "full",
      },
      {
        path: "order/manage-order/:orderId",
        loadChildren: () =>
          import("./orders/order/order.module").then((m) => m.OrderModule),
        canActivate: [AuthGuard],
        resolve: {
          order: OrderResolver,
        },
        data: {
          role: [
            "superadmin",
            "admin",
            "seller",
            "seller_external",
            "warehouser",
          ],
          permissions: ["UPDATE_NOT_HARDENED_ORDER"],
        },
      },
      {
        path: "notifications",
        component: NotificationsListComponent,
        data: {
          title: "Powiadomienia",
        },
      },
      {
        path: "order/costs/:orderId",
        loadChildren: () =>
          import("./orders/order/costs/costs.module").then(
            (m) => m.CostsModule
          ),
        canActivate: [AuthGuard],
        resolve: {
          order: OrderResolver,
        },
        data: {
          permissions: ["VIEW_HARDENED_ORDER_COSTS_DATA"],
        },
      },
      {
        path: "annexes",
        loadChildren: () =>
          import("./annexes/annexes-list/annexes-list.module").then(
            (m) => m.AnnexesListModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: [
            "superadmin",
            "admin",
            "seller",
            "warehouse_manager",
            "warehouser",
          ],
        },
      },
      {
        path: "annex/create-annex",
        loadChildren: () =>
          import("./annexes/annex-create/annex-create.module").then(
            (m) => m.AnnexModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin", "seller"],
          permissions: ["CREATE_ANNEX"],
        },
      },
      {
        path: "submissions",
        loadChildren: () =>
          import("./submissions/submissions-list/submissions-list.module").then(
            (m) => m.SubmissionsListModule
          ),
        canActivate: [AuthGuard],
        data: {
          permissions: ["READ_ALL_SUBMISSION_LIST"],
        },
      },
      {
        path: "submission/manage-submission",
        redirectTo: "submission/manage-submission/",
        pathMatch: "full",
      },
      {
        path: "submission/manage-submission/:submissionId",
        loadChildren: () =>
          import("./submissions/submission/submission.module").then(
            (m) => m.SubmissionModule
          ),
        canActivate: [AuthGuard],
        resolve: {
          submission: SubmissionResolver,
        },
        data: {
          permissions: ["CREATE_SUBMISSION"],
        },
      },

      {
        path: "users",
        loadChildren: () =>
          import("./user/user-list/user-list.module").then(
            (m) => m.UserListModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin"],
        },
      },
      {
        path: "user/register",
        loadChildren: () =>
          import("./user/register/register.module").then(
            (m) => m.RegisterModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin"],
        },
      },
      {
        path: "translations",
        loadChildren: () =>
          import("./translations/translations.module").then(
            (m) => m.TranslationsModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin", "translator"],
          permissions: ["VIEW_AND_UPDATE_TRANSLATIONS"],
        },
      },
      {
        path: "language/create",
        loadChildren: () =>
          import("./language/language-create/language-create.module").then(
            (m) => m.LanguageCreateModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin", "translator"],
        },
      },
      {
        path: "message/create",
        loadChildren: () =>
          import("./orders/thank-you-message/thank-you-message.module").then(
            (m) => m.ThankYouMessageDialogModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin", "seller", "seller_external"],
        },
      },
      {
        path: "dialog",
        loadChildren: () =>
          import("./shared/dialog/dialog.module").then((m) => m.DialogModule),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin", "seller", "seller_external"],
        },
      },
      {
        path: "resize-image",
        loadChildren: () =>
          import("./shared/resize-image/dialog.module").then(
            (m) => m.ResizeImageDialogModule
          ),
        canActivate: [AuthGuard],
        data: {
          role: ["superadmin", "admin", "seller", "seller_external"],
        },
      },
    ],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./user/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./user/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "reset-password",
    loadChildren: () =>
      import("./user/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
    data: {
      role: [],
    },
  },
  // {
  //   path: "**",
  //   redirectTo: "/dashboard",
  // },
];
