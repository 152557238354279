import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { catchError, tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { NotificationType } from "./notification.enum";
import { Notification } from "./notification";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";
  url = environment.url;
  unreadNotificationsCounter = 0;

  constructor(
    private http: HttpClient,
    private toast: MatSnackBar,
    private _translateService: TranslateService
  ) {}

  // getNotifications(
  //   skip: number,
  //   limit: number,
  //   type?: "orders" | "submissions" | "financials"
  // ): Observable<Notification[]> {
  //   let params = new HttpParams()
  //     .set("skip", skip.toString())
  //     .set("limit", limit.toString());

  //   if (type) {
  //     params = params.set("type", type);
  //   }

  //   return this.http.get<Notification[]>(`${this.url}/notifications`, {
  //     params,
  //   });
  // }

  getNotifications(
    skip: number,
    limit: number,
    type?: "orders" | "submissions" | "financials" | ""
  ): Observable<{ date: string; notifications: Notification[] }[]> {
    const params: { [key: string]: any } = { skip, limit };
    if (type) {
      params.type = type;
    }

    return this.http
      .get<{ date: string; notifications: Notification[] }[]>(
        `${this.url}/notifications`,
        { params }
      )
      .pipe(
        catchError((error) => {
          console.error("Error fetching notifications:", error);
          throw error;
        })
      );
  }

  /**
   * Funkcja odpowiedzialna za określenie czy powiadomienie zostało odczytane
   * @since 13.05.2024
   * @param notification
   * @param user
   * @returns
   */
  checkIfNotificationIsRead(notification: Notification, user: any) {
    if (!notification.viewedBy || notification.viewedBy.length === 0) {
      return false;
    } else {
      const viewedBy = notification.viewedBy.find((viewedBy: string) => {
        return viewedBy === user.userId;
      });
      return viewedBy ? true : false;
    }
  }

  /**
   * Funkcja odpowiedzialna za tworzenie powiadomienia
   * @since 08.05.2024
   * @returns
   */
  createNotification(
    type: NotificationType,
    orderId: string,
    description: string
  ) {
    const options = {
      type: type,
      order: orderId,
      description,
    };
    // Utwardzenie zamówienia
    // if (type == NotificationType.order_harden) {
    //   options.description = `Utwardzono
    //   ${details.orderTitle.countBeforeHardening}/SPECYFIKACJA/${details.orderTitle.year}`;
    // }
    // // Dodanie zaliczki do zamówienia
    // else if (type == NotificationType.order_advance_payment_added) {
    //   // Zlecenie
    //   if (details.offerChoice.code == "order") {
    //     options.description = `Dodano zaliczkę do ${details.orderTitle.countBeforeHardening}/SPECYFIKACJA/${details.orderTitle.year}`;
    //   }
    //   // Specyfikacja
    //   else {
    //     options.description = `Dodano zaliczkę do ${details.orderTitle.count}/SPECYFIKACJA/${details.orderTitle.year}`;
    //   }
    // }
    // Dodanie kosztu do zamówienia
    // else if (type == NotificationType.order_cost_added) {
    //   // Zlecenia
    //   options.description = `Dodano koszt do ${details.orderTitle.countBeforeHardening}/SPECYFIKACJA/${details.orderTitle.year}`;
    // }
    // // Archiwizacja zamówienia
    // else if (type == NotificationType.order_archive) {
    //   // Zlecenie
    //   if (details.offerChoice.code == "order") {
    //     options.description = `Zarchiwizowano ${details.orderTitle.countBeforeHardening}/SPECYFIKACJA/${details.orderTitle.year}`;
    //   }
    //   // Specyfikacja
    //   else {
    //     options.description = `Zarchiwizowano ${details.orderTitle.count}/SPECYFIKACJA/${details.orderTitle.year}`;
    //   }
    // }
    // Przesunięcie elementu produkcji
    // else if (type == NotificationType.production_element_move) {
    //   options.description = `Przesunięto element produkcji`;
    // }
    return this.http.post<any>(`${this.url}/notifications`, options).pipe(
      tap((res) => {
        this.getUnreadNotificationsCounter().subscribe(
          (counter) => (this.unreadNotificationsCounter = counter)
        );
      })
    );
  }

  markAsRead(notificationId: string) {
    return this.http
      .patch<any>(
        `${this.url}/notifications/mark-as-read/${notificationId}`,
        {}
      )
      .pipe(
        tap((res) => {
          this.getUnreadNotificationsCounter().subscribe(
            (counter) => (this.unreadNotificationsCounter = counter)
          );
        })
      );
  }

  /**
   * Funkcja odpowiedzialna za pobranie licznika nieodczytanych powiadomień
   * @since 09.05.2024
   * @returns
   */

  set setUnreadNotificationsCounter(value: number) {
    this.unreadNotificationsCounter = value;
  }

  getUnreadNotificationsCounter() {
    return this.http.get<number>(
      `${this.url}/notifications/unread-notifications-count`
    );
  }

  canViewNotification(user: any, notification: Notification) {
    if (user.role.code === "superadmin") {
      return true;
    }
    const canView = user?.notifications?.includes(notification?.type);
    return canView;
  }

  /**
   * Funkcja odpowiedzialna za pobranie kilku ostatnich powiadomień
   * @param user
   * @returns
   */
  getLastNotifications() {
    return this.http
      .get<Notification[]>(`${this.url}/notifications/last-notifications`)
      .pipe(
        tap((res) => {
          this.getUnreadNotificationsCounter().subscribe(
            (counter) => (this.unreadNotificationsCounter = counter)
          );
        })
      );
  }
}
