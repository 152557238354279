import { Component, Input, SimpleChanges, ViewChild } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatMenu, MatMenuModule, MatMenuTrigger } from "@angular/material/menu";
import { Observable } from "rxjs";
import { NotificationService } from "../notification.service";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatBadgeModule } from "@angular/material/badge";
import { Notification } from "../notification";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
@Component({
  selector: "app-notifications-last-list",
  templateUrl: "./notifications-last-list.component.html",
  styleUrls: ["./notifications-last-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
  ],
})
export class NotificationsLastListComponent {
  user: any;
  notifications$: Observable<Notification[]>;
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  @Input() isMenuOpened: boolean;
  constructor(
    private _notificationService: NotificationService,
    private jwtHelper: JwtHelperService,
    private _router: Router
  ) {}

  /**
   * On changes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    // W przypadku otworzenia menu
    if (changes.isMenuOpened) {
      if (this.isMenuOpened) {
        this.notifications$ = this._notificationService.getLastNotifications();
      }
    }
  }

  ngOnInit() {
    this.notifications$ = this._notificationService.getLastNotifications();
    this.getCurrentUser();
  }

  /**
   * Funkcja odpowiedzialna za pobranie danych dot. zalogowanego użytkownika
   * @since 13.12.2022
   */
  private getCurrentUser() {
    const token = localStorage.getItem("id_token");
    if (token) {
      this.user = this.jwtHelper.decodeToken(token);
    }
  }

  /**
   * Funkcja odpowiedzialna za przekierowanie do listy powiadomień
   * @since 13.05.2024
   */
  navigateToNotificationsList() {
    this._router.navigate(["/notifications"]);
  }

  /**
   * Funkcja odpowiedzialna za przekierowanie do zamówienia
   * @since 10.05.2024
   * @param orderId
   */
  navigateToOrder(orderId: string) {
    this._router.navigate(["/order/manage-order", orderId]);
  }

  /**
   * Funkcja odpowiedzialna za przekierowanie do modułu kosztowego zamówienia
   * @since 14.05.2024
   * @param orderId
   */
  navigateToOrderCosts(orderId: string) {
    this._router.navigate(["/order/costs", orderId]);
  }

  private checkIfNotificationIsRead(notification: Notification) {
    return this._notificationService.checkIfNotificationIsRead(
      notification,
      this.user
    );
  }

  /**
   * Funkcja odpowiedzialna za oznaczenie powiadomienia jako obejrzanego
   * @since 09.05.2024
   * @param notification
   */
  markAsRead(event: Event, notification: Notification) {
    event.stopPropagation();
    if (this.checkIfNotificationIsRead(notification)) {
      return;
    } else {
      this._notificationService
        .markAsRead(notification._id)
        .subscribe((res) => {
          if (res) {
            if (!notification.viewedBy) {
              notification.viewedBy = [];
            }
            notification.viewedBy = res.viewedBy;
          }
        });
    }
  }
}
