import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { OrderService } from "../order.service";

@Injectable({
  providedIn: "root",
})
export class OrderResolver implements Resolve<any> {
  /**
   * Constructor
   *
   */
  constructor(private _orderService: OrderService, private _router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Dane związane z pojedynczym zamówieniem
   *
   * @private
   */
  private _getOrderById(orderId: string): Observable<any> {
    if (orderId) {
      return this._orderService.getSingleOrder(orderId);
    } else {
      return of("");
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    if (route.paramMap.get("orderId")) {
      return this._getOrderById(route.paramMap.get("orderId") || "");
    } else {
      return of("");
    }
  }
}
