export const Notification = {
  order_harden: "Utwardzenie zamówień",
  order_cost_added: "Dodanie kosztu do zamówienia",
  order_advance_payment_added: "Dodanie zaliczki do zamówienia",
  order_archive: "Archiwizacja zamówienia",
  production_element_move: "Przesunięcie elementu produkcji",
  financial_quarter_report_edit: "Edycja raportu finansowego",
  annex_added: "Dodanie aneksu",
  submission_harden: "Utwardzenie zgłoszenia",
};

export enum NotificationType {
  order_harden = "order_harden",
  order_cost_added = "order_cost_added",
  order_advance_payment_added = "order_advance_payment_added",
  order_archive = "order_archive",
  production_element_move = "production_element_move",
  financial_quarter_report_edit = "financial_quarter_report_edit",
  annex_added = "annex_added",
  submission_harden = "submission_harden",
}

export const OrderNotifications = [
  NotificationType.order_harden,
  NotificationType.order_cost_added,
  NotificationType.order_advance_payment_added,
  NotificationType.order_archive,
  NotificationType.annex_added,
  NotificationType.production_element_move,
];
