import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { tap, shareReplay, catchError } from "rxjs/operators";
import * as moment from "moment";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class UserService {
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";
  url = environment.url;

  private _viewOrderEquipmentCosts: BehaviorSubject<any> = new BehaviorSubject(
    null
  );

  constructor(
    private http: HttpClient,
    private toast: MatSnackBar,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private _translateService: TranslateService
  ) {}

  /**
   * Getter for viewOrderEquipmentCosts
   */
  get viewOrderEquipmentCosts$(): Observable<any> {
    return this._viewOrderEquipmentCosts.asObservable();
  }

  login(email: string, password: string) {
    return this.http
      .post<any[]>(`${this.url}/auth/login`, { email, password })
      .pipe(
        catchError((error) =>
          of(
            this.toast.open("Niepoprawne dane logowania.", "Zamknij", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            })
          )
        ),
        tap((res) => {
          this.setSession(res);
          shareReplay();
        })
      );
  }
  register(details: any) {
    return this.http.post<any[]>(`${this.url}/auth/register`, details).pipe(
      tap((res) => {
        this.toast.open("Poprawnie utworzono użytkownika.", "Zamknij", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        this.router.navigateByUrl("/dashboard");
      })
    );
  }

  getUsers() {
    return this.http.get<any[]>(`${this.url}/users`);
  }

  /**
   * Pobiera pojedynczego producenta
   * @param id
   */
  getSingleUser(id: string) {
    return this.http.get<any>(`${this.url}/users/${id}`);
  }

  private setSession(authResult: any) {
    const expiresAt = moment().add(authResult.expiresIn, "second");
    localStorage.setItem("id_token", authResult.accessToken);

    let user = this.jwtHelper.decodeToken(authResult.accessToken);
    if (user.language) {
      localStorage.setItem(environment.specificatorLanguage, user.language);
    } else {
      localStorage.setItem(environment.specificatorLanguage, "PL");
    }
    if (user.permissions) {
      localStorage.setItem(
        "specificatorPermissions",
        JSON.stringify(user.permissions)
      );
    }
    // jezeli ustawienie suwaka jest wlaczone
    if (user.viewOrderEquipmentCosts == true) {
      localStorage.setItem(
        environment.specificatorViewOrderEquipmentCosts,
        JSON.stringify(user.viewOrderEquipmentCosts)
      );
    }
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
    localStorage.setItem("firstName", user.firstName);
    localStorage.setItem("role", user.role.code);
    this.router.navigateByUrl("/dashboard");
  }

  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("role");
    localStorage.removeItem("firstName");
    localStorage.removeItem(environment.specificatorLanguage);
    localStorage.removeItem(environment.specificatorViewOrderEquipmentCosts);
    localStorage.removeItem("specificatorPermissions");
    this.router.navigate(["/login"]);
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  /**
   * Odpowiada za aktualizację uzytkownika
   * @param details wartosci które powinny zostać zaktualizowane
   */
  updateUser(details: any, userId: string = ""): Observable<any> {
    return this.http
      .patch<any>(`${this.url}/users?userId=${userId}`, details)
      .pipe(
        tap((res) => {
          this.toast.open(
            this._translateService.instant("Pomyślnie wprowadzono zmiany."),
            this._translateService.instant("Zamknij"),
            {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            }
          );
          if (
            details?.viewOrderEquipmentCosts ||
            details?.viewOrderEquipmentCosts == false
          ) {
            this._viewOrderEquipmentCosts.next(details.viewOrderEquipmentCosts);
          }
        })
      );
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration || "{}");
    return moment(expiresAt);
  }
  deleteUser(id: string) {
    return this.http.delete<any>(`${this.url}/users/${id}`).pipe(
      tap((res) => {
        this.toast.open("Pomyślnie usunięto użytkownika.", "Zamknij", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      })
    );
  }

  // 1. Wysłanie żądania resetowania hasła
  requestPasswordReset(email: any) {
    return this.http
      .post<any>(`${this.url}/users/password-reset-request`, email)
      .pipe(
        tap((res) => {
          if (res.success == true) {
            this.toast.open(
              "Pomyślnie wysłano wiadomość. Sprawdź email",
              "Zamknij",
              {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
              }
            );
          } else {
            this.toast.open("Wystąpił błąd. Spróbuj ponownie", "Zamknij", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          }
        })
      );
  }
  // 2. Na potrzeby walidacji tokena
  validatePasswordResetToken(passwordResetToken: any) {
    return this.http
      .post<any>(
        `${this.url}/users/password-reset-token-validation`,
        passwordResetToken
      )
      .pipe(
        tap((res) => {
          if (res.success !== true) {
            this.toast.open("Token wygasł. Spróbuj ponownie", "Zamknij", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
            setTimeout(() => {
              this.router.navigateByUrl("/login");
            }, 1000);
          }
        })
      );
  }

  // 3. Przesłanie hasła i tokena
  resetPassword(credentials: any) {
    return this.http
      .put<any>(`${this.url}/users/token-password`, credentials)
      .pipe(
        tap((res) => {
          if (res.success !== true) {
            this.toast.open("Token wygasł. Spróbuj ponownie", "Zamknij", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
            setTimeout(() => {
              this.router.navigateByUrl("/login");
            }, 1000);
          } else {
            this.toast.open("Pomyślnie zresetowano hasło.", "Zamknij", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
            setTimeout(() => {
              this.router.navigateByUrl("/login");
            }, 1000);
          }
        })
      );
  }
}
