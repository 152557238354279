import { Component, AfterViewInit, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ROUTES } from "./menu-items";
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
//declare var $: any;
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  showMenu = "";
  showSubMenu = "";
  public sidebarnavItems: RouteInfo[] = [];

  // this is for the open close
  addExpandClass(element: string) {
    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  // End open close
  ngOnInit() {
    const role = localStorage.getItem("role");
    const permissions = JSON.parse(
      localStorage.getItem("specificatorPermissions") || "[]"
    );
    this.sidebarnavItems = ROUTES.filter(
      (sidebarnavItem) =>
        (sidebarnavItem && sidebarnavItem?.accessRole?.includes(role)) ||
        (sidebarnavItem &&
          sidebarnavItem?.accessPermission &&
          permissions.some((permission: any) =>
            sidebarnavItem?.accessPermission?.includes(permission)
          ))
    );
  }
}
