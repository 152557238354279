import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { tap, map } from "rxjs/operators";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { TranslateLoader, TranslateService } from "@ngx-translate/core";
@Injectable({
  providedIn: "root",
})
export class TranslationService implements TranslateLoader {
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";
  url = environment.url;
  show = true;
  showauto = false;
  autohide = true;
  constructor(
    private http: HttpClient,
    private toast: MatSnackBar,
    private _translateService: TranslateService
  ) {}

  /**
   * Na potrzeby tłumaczenia systemu i18n
   * @param lang
   * @returns
   */
  getTranslation(lang: string): Observable<any> {
    return this.http
      .get<any>(`${this.url}/translation/language/${lang}?filter=true`).pipe
      (map((response: JSON) => {
        return response;
      }));
  }

  /**
   * Pobiera listę tłumaczeń - pdf oraz systemowych, na potrzeby mozliwosci wyswietlenia tłumaczeń w module Tłumaczeniowym
   */
  getRawTranslations() {
    return this.http.get<any>(`${this.url}/translation`);
  }
  /**
   * Aktualizacja pojedynczej kategorii
   * @param id identyfikator kategorii
   * @param options przekazywane zmiany
   */
  updateTranslation(id: string, options: {}) {
    return this.http.patch<any>(`${this.url}/translation/${id}`, options).pipe(
      tap((res) => {
        this.toast.open(
          this._translateService.instant("Pomyślnie wprowadzono zmiany."),
          this._translateService.instant("Zamknij"),
          {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          }
        );
      })
    );
  }

  updateEntireTranslation(options: {}) {
    return this.http.patch<any>(`${this.url}/translation/all`, options).pipe(
      tap((res) => {
        this.toast.open(
          this._translateService.instant("Pomyślnie wprowadzono zmiany."),
          this._translateService.instant("Zamknij"),
          {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          }
        );
      })
    );
  }

  createTranslation(options: {}) {
    return this.http.post<any>(`${this.url}/translation/`, options).pipe(
      tap((res) => {
        this.toast.open(
          this._translateService.instant("Pomyślnie utworzono tłumaczenie."),
          this._translateService.instant("Zamknij"),
          {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          }
        );
      })
    );
  }
  deleteTranslation(id: string) {
    return this.http.delete<any>(`${this.url}/translation/${id}`).pipe(
      tap((res) => {
        this.toast.open(
          this._translateService.instant("Pomyślnie usunięto tłumaczenie."),
          this._translateService.instant("Zamknij"),
          {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          }
        );
      })
    );
  }
}
