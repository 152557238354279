import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "app/user/user.service";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";
  constructor(
    private userService: UserService,
    private toast: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem("id_token");

    if (this.userService.isLoggedIn() == true && idToken) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + idToken),
      });
      if (navigator.onLine) {
        return next.handle(cloned).pipe(
          catchError((error: HttpErrorResponse) => {
            console.log(error);
            if (error.status == 404) {
              this.toast.open("Nie znaleziono obiektu!", "Zamknij", {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
              });
              return throwError(error.error.message);
            } else if (error.status == 400) {
              this.toast.open(
                `Wystąpił błąd: ${error.error.message}`,
                "Zamknij",
                {
                  duration: 5000,
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                }
              );
              return throwError(error.message);
            }
            // Forbidden
            else if (error.status == 403) {
              this.toast.open(
                `Wystąpił błąd: ${error.error.message}`,
                "Zamknij",
                {
                  duration: 5000,
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                }
              );
              setTimeout(() => {
                this.userService.logout();
              }, 2500);
              return throwError(error.error.message);
            }
            // Conflict
            else if (error.status == 409) {
              this.toast.open(
                `Wystąpił błąd: ${error.error.message}`,
                "Zamknij",
                {
                  duration: 5000,
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                }
              );
              return throwError(error.error.message);
            } else {
              this.userService.logout();
              return throwError(error.error.message);
            }
          })
        );
      } else {
        this.toast.open("Brak połączenia internetowego!", "Zamknij", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        return throwError("Brak polaczenia internetowego");
      }
    } else {
      // this.userService.logout();
      return next.handle(req);
    }
  }
}
