import { Component, ElementRef, ViewChild } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { NotificationService } from "../notification.service";
import { Notification } from "../notification";
import { CommonModule } from "@angular/common";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { JwtHelperService } from "@auth0/angular-jwt";
import { UserService } from "app/user/user.service";
import { OrderNotifications } from "../notification.enum";
import { Router } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "app-notifications-list",
  templateUrl: "./notifications-list.component.html",
  styleUrls: ["./notifications-list.component.css"],
  standalone: true,
  imports: [
    MatMenuModule,
    CommonModule,
    MatButtonToggleModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class NotificationsListComponent {
  user: any;
  userDetails: any;
  loading: boolean = false;
  skip: number = 0;
  limit: number = 15;
  endOfList: boolean = false;
  groupedNotifications: { date: string; notifications: Notification[] }[] = [];
  @ViewChild("notificationList", { static: false })
  notificationList: ElementRef;

  orderNotifications = OrderNotifications;

  constructor(
    private _notificationService: NotificationService,
    private jwtHelper: JwtHelperService,
    private _userService: UserService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.getCurrentUser();
    this.loadNotifications();
  }

  /**
   * Funkcja odpowiedzialna za pobranie danych dot. zalogowanego użytkownika
   * @since 13.12.2022
   */
  private getCurrentUser() {
    const token = localStorage.getItem("id_token");
    if (token) {
      this.user = this.jwtHelper.decodeToken(token);
      this._userService.getSingleUser(this.user.userId).subscribe((res) => {
        this.userDetails = res;
        if (res?.role?.code == "seller_external") {
          this._userService.logout();
        }
      });
    }
  }

  /**
   * Funkcja weryfikujaca czy uzytkownik posiada chociaz jedno z uprawnien powiadomien, istotne w kwestii wyswietlenia zakładki
   * @since 14.05.2024
   * @returns
   */
  canViewOrderNotifications() {
    return this.orderNotifications?.some((type) =>
      this.userDetails?.notifications?.includes(type)
    );
  }

  /**
   * Funkcja odpowiedzialna za przekierowanie do zamówienia
   * @since 10.05.2024
   * @param orderId
   */
  navigateToOrder(orderId: string) {
    this._router.navigate(["/order/manage-order", orderId]);
  }

  /**
   * Funkcja odpowiedzialna za przekierowanie do modułu kosztowego zamówienia
   * @since 14.05.2024
   * @param orderId
   */
  navigateToOrderCosts(orderId: string) {
    this._router.navigate(["/order/costs", orderId]);
  }

  loadNotifications(type?: "orders" | "submissions" | "financials" | ""): void {
    if (this.loading) {
      return;
    }
    if (type || type == "") {
      this.groupedNotifications = [];
      this.skip = 0;
      this.limit = 15;
      this.endOfList = false;
    }
    this.loading = true;
    this._notificationService
      .getNotifications(this.skip, this.limit, type)
      .subscribe(
        (
          groupedNotifications: {
            date: string;
            notifications: Notification[];
          }[]
        ) => {
          if (groupedNotifications.length === 0) {
            this.endOfList = true;
            this.loading = false;
            return;
          }
          this.updateGroupedNotifications(groupedNotifications);
          this.loading = false;
          this.skip += this.limit;
        }
      );
  }

  onScroll(): void {
    if (this.loading || this.endOfList) {
      return;
    }
    const element = this.notificationList.nativeElement;
    const atBottom =
      element.scrollHeight - element.scrollTop === element.clientHeight;
    if (atBottom) {
      this.loadNotifications();
    }
  }

  /**
   * Funkcja odpowiedzialna za oznaczenie powiadomienia jako obejrzanego
   * @since 09.05.2024
   * @param notification
   */
  markAsRead(event: Event, notification: Notification) {
    event.stopPropagation();
    if (this.checkIfNotificationIsRead(notification)) {
      return;
    } else {
      this._notificationService
        .markAsRead(notification._id)
        .subscribe((res) => {
          if (res) {
            if (!notification.viewedBy) {
              notification.viewedBy = [];
            }
            notification.viewedBy = res.viewedBy;
          }
        });
    }
  }

  /**
   * Funkcja odpowiedzialna za sprawdzenie czy powiadomienie zostało odczytane
   * @since 13.05.2024
   * @param notification
   * @returns
   */
  private checkIfNotificationIsRead(notification: Notification) {
    return this._notificationService.checkIfNotificationIsRead(
      notification,
      this.user
    );
  }

  /**
   * Funkcja odpowiedzialna za aktualizację grup powiadomień
   * Na potrzeby sytuacji, gdy API zwraca dane zwiazane z data, która juz istnieje na liscie powiadomień
   * @since 13.05.2024
   * @param newGroups
   */
  updateGroupedNotifications(
    newGroups: { date: string; notifications: Notification[] }[]
  ): void {
    newGroups.forEach((newGroup) => {
      const existingGroupIndex = this.groupedNotifications.findIndex(
        (group) => group.date === newGroup.date
      );
      if (existingGroupIndex !== -1) {
        // Jeśli grupa już istnieje, dodaj nowe powiadomienia do istniejącej grupy
        this.groupedNotifications[existingGroupIndex].notifications.push(
          ...newGroup.notifications
        );
      } else {
        // Jeśli grupa nie istnieje, dodaj nową grupę do listy
        this.groupedNotifications.push(newGroup);
      }
    });
  }

  // loadNotifications(type?: "orders" | "submissions" | "financials"): void {
  //   if (this.loading) {
  //     return;
  //   }
  //   if (type) {
  //     this.notifications = [];
  //     this.skip = 0;
  //     this.limit = 15;
  //   }
  //   this.loading = true;
  //   this.notificationService
  //     .getNotifications(this.skip, this.limit, type)
  //     .subscribe((notifications: Notification[]) => {
  //       console.log(notifications);
  //       this.groupedNotifications =
  //         this.groupNotificationsByDate(notifications);
  //       this.loading = false;
  //       this.skip += this.limit;
  //       this.limit += this.limit;
  //     });
  // }

  // groupNotificationsByDate(
  //   notifications: Notification[]
  // ): { date: string; notifications: Notification[] }[] {
  //   const groupedNotifications: {
  //     date: string;
  //     notifications: Notification[];
  //   }[] = [];
  //   notifications.forEach((notification) => {
  //     const date = new Date(notification.createdAt).toLocaleDateString();
  //     const existingGroup = groupedNotifications.find(
  //       (group) => group.date === date
  //     );
  //     if (existingGroup) {
  //       existingGroup.notifications.push(notification);
  //     } else {
  //       groupedNotifications.push({ date, notifications: [notification] });
  //     }
  //   });
  //   return groupedNotifications; // Odwróć kolejność, aby najnowsze były na górze
  // }

  // onScroll(): void {
  //   const element = this.notificationList.nativeElement;
  //   const atBottom =
  //     element.scrollHeight - element.scrollTop === element.clientHeight;
  //   if (atBottom) {
  //     this.loadNotifications();
  //   }
  // }
}
