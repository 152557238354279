import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "Kokpit",
    icon: "",
    class: "nav-small-cap",
    label: "",
    labelClass: "",
    extralink: true,
    submenu: [],
    accessRole: [
      "seller",
      "seller_external",
      "user",
      "superadmin",
      "admin",
      "warehouse_manager",
      "warehouser",
    ],
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "mdi mdi-home",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessRole: [
      "seller",
      "seller_external",
      "user",
      "superadmin",
      "admin",
      "warehouse_manager",
      "warehouser",
    ],
  },
  {
    path: "/equipment",
    title: "Wyposażenie",
    icon: "mdi mdi-folder",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessRole: ["superadmin", "admin", "warehouse_manager"],
    accessPermission: ["VIEW_AND_UPDATE_EQUIPMENT"],
  },
  {
    path: "/conversions",
    title: "Zabudowy",
    icon: "mdi mdi-car",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessRole: ["superadmin", "admin", "warehouse_manager"],
    accessPermission: ["VIEW_AND_UPDATE_CONVERSIONS"],
  },
  {
    path: "/financial-reports",
    title: "Finanse",
    icon: "mdi mdi-numeric",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessPermission: [
      "MANAGE_FINANCIAL_REPORTS",
      "MANAGE_FINANCIAL_REPORT_WAREHOUSE_MODULE",
      "MANAGE_FINANCIAL_REPORT_ORDERS_MODULE",
    ],
  },
  {
    path: "",
    title: "Zamówienia",
    icon: "",
    class: "nav-small-cap",
    label: "",
    labelClass: "",
    extralink: true,
    submenu: [],
    accessRole: [
      "seller",
      "seller_external",
      "superadmin",
      "admin",
      "warehouse_manager",
      "warehouser",
    ],
  },
  {
    path: "/order/manage-order",
    title: "Nowe zamówienie",
    icon: "mdi mdi-plus",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessRole: ["seller", "seller_external", "superadmin", "admin"],
  },
  {
    path: "/orders",
    title: "Lista zamówień",
    icon: "mdi mdi-format-list-bulleted",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessRole: [
      "seller",
      "seller_external",
      "superadmin",
      "admin",
      "warehouse_manager",
      "warehouser",
    ],
  },
  {
    path: "/annexes",
    title: "Lista aneksów",
    icon: "mdi mdi-attachment",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessRole: [
      "seller",
      "superadmin",
      "admin",
      "warehouse_manager",
      "warehouser",
    ],
  },
  {
    path: "",
    title: "Produkcja",
    icon: "",
    class: "nav-small-cap",
    label: "",
    labelClass: "",
    extralink: true,
    submenu: [],
    accessPermission: ["VIEW_PRODUCTION"],
  },
  {
    path: "/production/board",
    title: "Board",
    icon: "mdi mdi-animation",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessPermission: ["VIEW_PRODUCTION"],
  },
  {
    path: "/production/stage/stage-dashboard",
    title: "Etapy",
    icon: "mdi mdi-database",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessPermission: ["VIEW_PRODUCTION"],
  },
  {
    path: "",
    title: "Zgłoszenia",
    icon: "",
    class: "nav-small-cap",
    label: "",
    labelClass: "",
    extralink: true,
    submenu: [],
    accessPermission: ["READ_ALL_SUBMISSION_LIST"],
  },
  {
    path: "/submission/manage-submission",
    title: "Nowe zgłoszenie",
    icon: "mdi mdi-plus",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessPermission: ["CREATE_SUBMISSION"],
  },
  {
    path: "/submissions",
    title: "Lista zgłoszeń",
    icon: "mdi mdi-format-list-bulleted",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessPermission: ["READ_ALL_SUBMISSION_LIST"],
  },
  {
    path: "",
    title: "Użytkownicy",
    icon: "",
    class: "nav-small-cap",
    label: "",
    labelClass: "",
    extralink: true,
    submenu: [],
    accessRole: ["superadmin", "admin"],
  },
  {
    path: "/user/register",
    title: "Nowy użytkownik",
    icon: "mdi mdi-plus",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessRole: ["superadmin", "admin"],
  },
  {
    path: "/users",
    title: "Lista użytkowników",
    icon: "mdi mdi-account",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessRole: ["superadmin", "admin"],
  },
  {
    path: "/translations",
    title: "Tłumaczenia",
    icon: "mdi mdi-translate",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [],
    accessRole: ["superadmin", "admin", "translator"],
    accessPermission: ["VIEW_AND_UPDATE_TRANSLATIONS"],
  },
];
