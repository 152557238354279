import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { SubmissionService } from "../submission.service";
import { Submission } from "../submission.model";
@Injectable({
  providedIn: "root",
})
export class SubmissionResolver implements Resolve<any> {
  /**
   * Constructor
   *
   */
  constructor(
    private _submissionService: SubmissionService,
    private _router: Router
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Funkcja zwracająca dane związane z pojedynczym zgłoszeniem
   * @since 13.12.2022
   * @private
   */
  private _getSubmissionById(
    submissionId: string
  ): Observable<Submission | ""> {
    if (submissionId) {
      return this._submissionService.getSingleSubmission(submissionId);
    } else {
      return of("");
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    if (route.paramMap.get("submissionId")) {
      return this._getSubmissionById(route.paramMap.get("submissionId") || "");
    } else {
      return of("");
    }
  }
}
