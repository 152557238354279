import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { tap } from "rxjs/operators";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { Submission } from "./submission.model";
import { Page, PageRequest } from "ngx-pagination-data-source";
import { BehaviorSubject, Observable } from "rxjs";

export interface SubmissionQuery {
  // status
  statusCode?: string;
  // rok utworzenia
  titleYear?: string;
  // tytuł
  title?: string;
  // twórca
  creator?: string;
  // nazwa firmy
  companyName?: string;
  // nazwa linii
  conversionName?: string;
  // numer vin
  vinNumber?: string;
  // nazwa statusu
  statusName?: string;
}
@Injectable({
  providedIn: "root",
})
export class SubmissionService {
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";
  url = environment.url;

  // Private
  private readonly _submission: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private http: HttpClient,
    private toast: MatSnackBar,
    private _translateService: TranslateService
  ) {}

  // getter zwróci ostatnią wyemitowaną wartość w _submission BehaviorSubject
  get submission$(): Observable<any> {
    return this._submission.asObservable();
  }

  // setter
  set submission(submission: Submission | null) {
    // Store the value
    this._submission.next(submission);
  }

  /**
   * Funkcja odpowiedzialna za pobranie listy zgłoszeń
   * @since 12.12.2022 zmiana
   */
  page(
    request: PageRequest<any>,
    query: SubmissionQuery
  ): Observable<Page<Submission>> {
    const pageIndex = request.page;
    const pageSize = request.size;
    const offset = pageIndex * pageSize;
    const sortOrder = request?.sort?.order == "asc" ? 1 : -1;
    const sortField = request?.sort?.property;
    const params: any = {
      ...query,
      sortField,
      sortOrder: sortOrder.toString(),
      pageIndex: pageIndex.toString(),
    };
    params["limit"] = pageSize.toString();
    params["offset"] = offset.toString();

    return this.http.get<Page<Submission>>(`${this.url}/submissions`, {
      params,
    });
  }

  /**
   * Funkcja odpowiedzialna za utworzenie zgłoszenia
   * @since 09.12.2022
   * @param submission
   * @returns
   */
  createSubmission(submission: Submission) {
    return this.http
      .post<Submission>(`${this.url}/submissions/`, submission)
      .pipe(
        tap((res) => {
          this.toast.open(
            this._translateService.instant("Pomyślnie utworzono."),
            this._translateService.instant("Zamknij"),
            {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            }
          );
        })
      );
  }

  /**
   * Funkcja odpowiedzialna za aktualizację pojedynczego zgłoszenia
   * @since 13.12.2022
   * @param submissionId identyfikator zgłoszenia
   * @param submission przekazywane zmiany
   */
  updateSubmission(submissionId: string, submission: Submission) {
    return this.http
      .patch<Submission>(`${this.url}/submissions/${submissionId}`, submission)
      .pipe(
        tap((res) => {
          this.toast.open(
            this._translateService.instant("Pomyślnie wprowadzono zmiany."),
            this._translateService.instant("Zamknij"),
            {
              duration: 1000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            }
          );
        })
      );
  }

  /**
   * Funkcja odpowiedzialna za wygenerowanie dokumentu pdf zgłoszenia
   * @since 23.12.2022
   * @param submissionId
   * @param submission
   * @param language
   * @returns
   */

  getSubmissionPdf(
    submissionId: string,
    submission: Submission,
    language: string
  ) {
    return this.http.post<Submission>(
      `${this.url}/submissions/generateSingleSubmissionPdf/${submissionId}?language=${language}`,
      { submission },
      {
        responseType: "blob" as "json",
      }
    );
  }

  /**
   * Funkcja odpowiedzialna za usunięcie zgłoszenia
   * @since 15.12.2022
   * @param id
   * @returns
   */
  deleteSubmission(id: string) {
    return this.http.delete<Submission>(`${this.url}/submissions/${id}`).pipe(
      tap((res) => {
        this.toast.open(
          this._translateService.instant("Pomyślnie usunięto zgłoszenie."),
          this._translateService.instant("Zamknij"),
          {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          }
        );
      })
    );
  }

  /**
   * Funkcja pobierająca szczegóły pojedynczego zgłoszenia
   * @since 13.12.2022
   * @param submissionId
   */
  getSingleSubmission(submissionId: string) {
    return this.http
      .get<Submission>(`${this.url}/submissions/${submissionId}`)
      .pipe(
        tap((submission: Submission) => {
          this._submission.next(submission);
        })
      );
  }

  /**
   * Funkcja odpowiedzialna za dodanie obrazów do zgłoszenia
   * @since 30.12.2022
   * @param submissionId
   * @param photos
   * @returns
   */
  uploadSubmissionImages(submissionId: string, photos: FormData) {
    if (photos) {
      return this.http
        .patch<any>(`${this.url}/submissions/${submissionId}/images`, photos, {
          reportProgress: true,
          observe: "events",
        })
        .pipe(
          tap((res: any) => {
            if (res.status == "200") {
              this.toast.open(
                this._translateService.instant("Pomyślnie dodano zdjęcia."),
                this._translateService.instant("Zamknij"),
                {
                  duration: 1000,
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                }
              );
            }
          })
        );
    }
  }

  /**
   * Funkcja odpowiedzialna za dodanie dokumentu ZK do zgłoszenia
   * @since 10.01.2023
   * @param submissionId
   * @param formData
   * @returns
   */
  uploadSubmissionZkDocument(submissionId: string, formData: FormData) {
    return this.http.post<Submission>(
      `${this.url}/submissions/${submissionId}/zk-document`,
      formData
    );
  }

  /**
   * Końcówka odpowiedzialna za pobranie dokumentu ZK do zgłoszenia
   * @since 10.01.2023
   * @param submissionId
   * @param fileName
   * @returns
   */
  getSubmissionZkDocument(submissionId: string, fileName: string) {
    return this.http.post(
      `${this.url}/files/submissions/${submissionId}/zk-documents/${fileName}`,
      {},
      { responseType: "blob" }
    );
  }

  /**
   * Funkcja odpowiedzialna za utworzenie nowej numeracji dla zgłoszenia
   * @since 09.12.2022
   */
  createSubmissionNumeration(statusCode: string, year: number) {
    return this.http.get<any>(
      `${this.url}/submissions/count/numeration/?year=${year}&statusCode=${statusCode}`
    );
  }

  /**
   * Funkcja odpowiedzialna za usunięcie dokumentu ZK
   * @since 10.01.2023
   * @param submissionId
   * @param filepath
   * @returns
   */
  async deleteSubmissionZkDocument(
    submissionId: string,
    filepath: {
      filepath: string;
    }
  ) {
    return this.http.patch<any>(
      `${this.url}/submissions/delete-zk-document/${submissionId}`,
      filepath
    );
  }
}
