import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";
  url = environment.url;
  show = true;
  showauto = false;
  autohide = true;
  constructor(
    private http: HttpClient,
    private toast: MatSnackBar,
    private _translateService: TranslateService
  ) {}

  /**
   * Pobiera listę języków
   */
  getLanguages() {
    return this.http.get<any>(`${this.url}/language`);
  }
  /**
   * Aktualizacja
   * @param id identyfikator
   * @param options przekazywane zmiany
   */
  updateLanguage(id: string, options: {}) {
    return this.http.patch<any>(`${this.url}/language/${id}`, options).pipe(
      tap((res) => {
        this.toast.open(
          this._translateService.instant("Pomyślnie wprowadzono zmiany."),
          this._translateService.instant("Zamknij"),
          {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          }
        );
      })
    );
  }
  createLanguage(options: {}) {
    return this.http.post<any>(`${this.url}/language/`, options).pipe(
      tap((res) => {
        this.toast.open(
          this._translateService.instant("Pomyślnie utworzono język."),
          this._translateService.instant("Zamknij"),
          {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          }
        );
      })
    );
  }
  deleteLanguage(id: string) {
    return this.http.delete<any>(`${this.url}/language/${id}`).pipe(
      tap((res) => {
        this.toast.open(
          this._translateService.instant("Pomyślnie usunięto język."),
          this._translateService.instant("Zamknij"),
          {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          }
        );
      })
    );
  }
}
