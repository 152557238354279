<mat-menu class="menu-notifications" [overlapTrigger]="false">
  <div class="notifications-topbar">Powiadomienia
    <button (click)="navigateToNotificationsList()" mat-flat-button mat-button color="accent">Wszystkie</button>
  </div>
  <ng-container *ngIf="notifications$ | async as notifications">
    <ng-container *ngFor="let notification of notifications; let i = index">
      <button (click)="markAsRead($event, notification)" class="menu-notification-item" mat-menu-item
        [ngClass]="{'menu-border-bottom': i !== notifications.length - 1}">
        <!-- <mat-icon>{{ notification.icon }}</mat-icon> -->
        <div class="notification-creator">
          <strong>{{notification.createdBy.firstName}} {{notification.createdBy.lastName}}</strong>
          <mat-icon class="notification-circle"
            [ngClass]="checkIfNotificationIsRead(notification) ? 'circle-read' : 'circle-unread'">circle</mat-icon>
        </div>

        <p style="margin-bottom: 0;">{{ notification.description }}</p>
        <div class="notification-footer">
          <small>{{ notification.createdAt | date : "dd.MM.yyyy, HH:mm" }}</small>
          <ng-container
            *ngIf="['order_harden', 'order_advance_payment_added', 'order_archive'].includes(notification.type)">
            <button (click)="navigateToOrder(notification.order)" class="notification-details" mat-icon-button>
              <mat-icon color="primary">navigate_next</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="['order_cost_added'].includes(notification.type)">
            <button (click)="navigateToOrderCosts(notification.order)" class="notification-details" mat-icon-button>
              <mat-icon color="primary">navigate_next</mat-icon>
            </button>
          </ng-container>

        </div>
      </button>
    </ng-container>
  </ng-container>
</mat-menu>