<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav mr-auto float-left">
  <li (click)="toggleSidebar.emit()" class="nav-item m-l-10 d-block"> <a
      class="nav-link sidebartoggler text-muted waves-effect waves-dark" href="javascript:void(0)"><i
        class="ti-menu"></i></a> </li>
</ul>
<!-- ============================================================== -->
<!-- User profile and search -->
<!-- ============================================================== -->
<ul class="navbar-nav my-lg-0 float-right">



  <mat-form-field class="language-menu" style="max-width: 80px;">
    <mat-select #languageSelect (selectionChange)="setLanguage($event)" [value]="language">
      <mat-select-trigger>
        <span class="rectangle" style="max-height: 35px;"><img class="flag"
            style="max-height:20px; max-width: 25px; box-shadow: 0 0 1px grey;"
            [src]="'assets/svg-country-flags/svg/' + language.toLowerCase() + '.svg'"></span>
      </mat-select-trigger>
      <mat-option value="PL"> <span class="rectangle"><img class="flag" style="max-height:20px; max-width: 25px"
            [src]="'assets/svg-country-flags/svg/pl.svg'"></span></mat-option>
      <mat-option *ngFor="let language of languages" [value]="language.name">
        <span class="rectangle"><img class="flag" style="max-height:20px; max-width: 25px;"
            [src]="'assets/svg-country-flags/svg/' + language.name.toLowerCase() + '.svg'"></span>
      </mat-option>

    </mat-select>
  </mat-form-field>

  <!-- Powiadomienia -->
  <ng-container *ngIf="user?.role?.code !== 'seller_external'">
    <button style="margin-right: 15px;" (menuOpened)="onNotificationMenuOpened()"
      (menuClosed)="onNotificationMenuClosed()" [matBadge]="unreadNotificationsCounter"
      [matBadgeHidden]="unreadNotificationsCounter === 0" matBadgeOverlap="false" mat-icon-button
      [matMenuTriggerFor]="x.menu" class="notification-menu-trigger">
      <mat-icon color="accent">notifications</mat-icon>
    </button>
    <app-notifications-last-list [isMenuOpened]="isMenuOpened" #x></app-notifications-last-list>
  </ng-container>
  <!-- // Powiadomienia -->



  <!-- ============================================================== -->
  <!-- Profile -->
  <!-- ============================================================== -->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark" href="javascript:void(0)"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{'Cześć' | translate}},
      <strong>{{firstName}}</strong> <i style="padding-left: 5px" class="fa fa-chevron-down"></i></a>
    <div class="dropdown-menu" ngbDropdownMenu>
      <ul class="dropdown-user">
        <li *ngIf="role == 'superadmin'">
          <a href="#">
            <mat-slide-toggle (change)="updateViewOrderEquipmentCostsValue()" [(ngModel)]="viewOrderEquipmentCosts">
              {{'Wyświetl koszty' | translate}}</mat-slide-toggle>
          </a>
        </li>
        <li><a href="#" (click)="logout()"><i class="fa fa-power-off"></i> {{'Wyloguj' | translate}}</a></li>
      </ul>
    </div>
  </li>
</ul>