export const environment = {
  production: true,
  url: "https://specyfikator.autocuby.eu",
  whitelistedDomains: [
    "http://specyfikator.autocuby.eu/",
    "specyfikator.autocuby.eu/",
    "specyfikator.autocuby.eu",
    "https://specyfikator.autocuby.eu",
  ],
  specificatorLanguage: "specificatorLanguage",
  specificatorViewOrderEquipmentCosts: "specificatorViewOrderEquipmentCosts",
  hardenedDocumentEmailConfirmation: "dominika.k@autocuby.pl",
  plnToEuroExchangeRate: 4.6,
};
